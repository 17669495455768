import { useQuery } from "@apollo/client";
import _ from "lodash";
import {
  GetProductsDocument,
  GetProductsLengthDocument,
  GetProductsLengthQuery,
  GetProductsLengthQueryVariables,
  GetProductsQuery,
  GetProductsQueryVariables,
} from "../graphql/queries/__generated__/products.query";

const useGetProducts = (variables: GetProductsQueryVariables) => {
  const { data, loading, error, fetchMore, networkStatus, refetch } = useQuery<
    GetProductsQuery,
    GetProductsQueryVariables
  >(GetProductsDocument, {
    fetchPolicy: "cache-first",
    variables: {
      ...variables,
      filter: {
        ...variables.filter,
        published: {
          eq: true,
        },
      },
    },
  });

  const {
    data: result,
    loading: _loading,
    error: _error,
  } = useQuery<GetProductsLengthQuery, GetProductsLengthQueryVariables>(
    GetProductsLengthDocument,
    {
      variables: {
        ...variables,
        filter: {
          ...variables.filter,
          published: {
            eq: true,
          },
        },
      },
    }
  );

  const products = data?.getProducts
    ? _.filter(data?.getProducts, (p) => Number(p?.qty) > 0)
    : [];
  const productsCount = !_loading && !_error ? result?.getProductsLength : 0;
  return {
    products,
    productsCount,
    loading,
    error,
    fetchMore,
    networkStatus,
    refetch,
  };
};

export default useGetProducts;
