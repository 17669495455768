import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TendoLogo from "../assets/images/tendo-logo.png";
import Search from "./Search";
import { useMediaQuery } from "react-responsive";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useAppSelector } from "../redux/hook";
import { ShoppingBagIcon, ChatAlt2Icon } from "@heroicons/react/outline";
import { NavLink } from "react-router-dom";
import LgAccountBtn from "./LgAccountBtn";
import { uniqueId } from "lodash";
import { useFreshDeskScript } from "../hooks/useFreshdeskScript";
import { initFreshChat } from "../services/freshchat.service";

const Header = ({ title, showBack, search, goBack, data }) => {
  const { cart } = useAppSelector((state) => state.cart);
  const { user } = useAppSelector((state) => state.auth);
  const history = useNavigate();
  const _goBack = () => history(-1);

  const lgNav = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "Categories",
      path: "/categories",
    },
    {
      title: "Explore",
      path: "/explore",
    },
    {
      title: "Search",
      path: "/search",
    },
  ];

  const [isLoadedFreshChat, setLoadedFreshChat] = useState(false);

  function openFreshChat() {
    const fcWidget = window.fcWidget;
    if (fcWidget && fcWidget.isLoaded()) {
      fcWidget.user.setFirstName(user ? user.name.split(" ")[0] : "");
      fcWidget.user.setLastName(user ? user.name.split(" ")[1] : "");
      fcWidget.setExternalId(user ? user.username : uniqueId());
      fcWidget.user.setEmail(user ? user.email : "");
      fcWidget.user.setPhone(user ? user.phone : "");

      fcWidget.show();
      fcWidget.open();
    }
  }

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const locations = useLocation();
  const currentPath = locations.pathname.includes("/cart");
  const isAccountPage = locations.pathname.includes("/accounts");

  useFreshDeskScript(
    !isAccountPage
      ? () => {
          initFreshChat();
          const fcWidget = window.fcWidget;
          fcWidget.on("widget:loaded", () => fcWidget.hide());
          setLoadedFreshChat(true);
          fcWidget.on("widget:closed", () => fcWidget.hide());
        }
      : () => null
  );

  return (
    <div className="bg-white py-3 flex lg:items-center flex-col lg:flex-row fixed z-10 top-0 w-screen px-4">
      <div className="flex items-center w-full justify-between">
        <div className="flex justify-center items-center mr-1">
          {showBack ? (
            <div
              className="flex items-center text-base text-tendo-active cursor-pointer"
              onClick={goBack ? goBack : _goBack}
            >
              <ArrowLeftIcon className="text-tendo-active h-6 w-6" />
            </div>
          ) : (
            <img
              src={TendoLogo}
              alt="tendo logo"
              height={25}
              width={25}
              className="rounded-sm"
            />
          )}
        </div>
        <div className="text-center flex-1 lg:mx-auto lg:flex lg:justify-center lg:items-center">
          <span className="text-gray-800 font-bold truncate w-52 text-base lg:hidden block">
            {title}
          </span>
          <nav className="hidden lg:flex space-x-10">
            {lgNav.map((nav) => (
              <NavLink
                key={nav.title}
                to={nav.path}
                className={({ isActive }) =>
                  isActive
                    ? "text-tendo-active font-semibold text-sm"
                    : "text-sm text-gray-800 font-medium"
                }
              >
                {nav.title}
              </NavLink>
            ))}
          </nav>
        </div>
        {isTabletOrMobile && !currentPath && (
          <div
            className="relative cursor-pointer"
            onClick={() => history("/cart")}
          >
            <ShoppingBagIcon className="h-5 w-5 text-gray-800 mr-2" />
            {cart && cart.products.length > 0 && (
              <div className="absolute top-0 right-0 bg-tendo-active text-white text-xs px-1 rounded-full">
                {cart.products.length}
              </div>
            )}
          </div>
        )}
        {isTabletOrMobile && !isAccountPage && (
          <ChatAlt2Icon
            className="h-5 w-5"
            onClick={() => {
              if (isLoadedFreshChat) {
                openFreshChat();
              }
            }}
          />
        )}
      </div>
      {search && (
        <div className="flex w-full lg:w-96 px-2 mt-3 lg:mt-0">
          <Search data={data} onSearch={search} />
        </div>
      )}
      {!isTabletOrMobile && !currentPath && (
        <div className="flex items-center space-x-4 ml-4">
          <div
            className="relative cursor-pointer"
            onClick={() => history("/cart")}
          >
            <ShoppingBagIcon className="h-5 w-5 text-gray-800 mr-2" />
            {cart?.products?.length > 0 && (
              <div className="absolute top-0 right-0 bg-tendo-active text-white text-xs px-1 rounded-full">
                {cart.products.length}
              </div>
            )}
          </div>
          <ChatAlt2Icon
            className="h-5 w-5"
            onClick={() => {
              if (isLoadedFreshChat) {
                openFreshChat();
              }
            }}
          />
          <LgAccountBtn />
        </div>
      )}
    </div>
  );
};

export default Header;
