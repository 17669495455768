import { Select } from "antd";
import React, { useMemo } from "react";
import { Condition, Product } from "../graphql/types";
import useGetProducts from "../hooks/useGetProducts";
import { useAppDispatch } from "../redux/hook";
import { setSearchProduct } from "../redux/slice/product.slice";
import SearchItem from "./SearchItem";

interface Props {
  data: Product[];
  onSearch?: (e: string) => void;
}

const Search = ({ data, onSearch }: Props) => {
  const { Option } = Select;
  const dispatch = useAppDispatch();
  const { loading, refetch } = useGetProducts({});

  const options = useMemo(() => {
    return data.map((item) => (
      <Option key={item.sku} value={item.id}>
        <SearchItem item={item} />
      </Option>
    ));
  }, [Option, data]);

  function onChange(value: any) {
    console.log(`selected`);
  }

  async function _onSearch(val: string) {
    // console.log("search:", val);
    if (val.length >= 3) {
      if (data.length !== 0) {
        const filteredData = data.filter((item) => {
          return (
            item.title.toLowerCase().includes(val.toLowerCase()) ||
            item?.sku?.toLowerCase().includes(val.toLowerCase()) ||
            item?.tags?.includes(val.toLowerCase())
          );
        });
        if (filteredData.length !== 0) {
          dispatch(setSearchProduct(filteredData));
        } else {
          const { data: moreFilterData } = await refetch({
            filter: {
              qty: {
                gt: 0,
              },
              title: {
                like: val,
              },
              sku: {
                like: val,
              },
              tags: {
                like: val,
              },
            },
            condition: Condition.Or,
          });
          dispatch(setSearchProduct(moreFilterData.getProducts as Product[]));
        }
      } else {
        dispatch(setSearchProduct(data));
      }
    } else {
      dispatch(setSearchProduct(data));
    }
  }

  return (
    <Select
      showSearch
      placeholder="Search product name, sku or tag"
      optionFilterProp="children"
      onChange={onSearch ? (e) => onSearch(e) : (e) => onChange(e)}
      onSearch={onSearch ? (e) => onSearch(e) : (e) => _onSearch(e)}
      filterOption={false}
      className="w-full search-bar"
      loading={loading}
    >
      {options}
    </Select>
  );
};

export default Search;
