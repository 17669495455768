import ScreenWrapper from "../../../components/common/ScreenWrapper"
import successImg from '../../../assets/images/successimg.svg'
import { Link } from "react-router-dom"

const OrderSuccess = () => {
    return (
        <ScreenWrapper showBackBtn title="Success">
            <div className="mx-4 flex flex-col lg:mb-12 py-16 lg:max-w-7xl lg:mx-auto lg:px-4">
                <div className="pt-20 flex items-center flex-col">
                    <img className="h-72 w-full" src={successImg} alt="success" />
                    <span className="font-semibold">Your order has been placed successfully 🎊 </span>
                    <Link
                        to={'/'}
                        className={` flex mt-4 justify-center py-2 px-4 border text-sm  rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 border-blue-5500 text-blue-500 `}
                    >
                        Back to home
                    </Link>
                </div>
            </div>
        </ScreenWrapper>
    )
}

export default OrderSuccess