import { BrowserRouter } from "react-router-dom";
import AppLayout from "./layouts/AppLayout";
import { MantineProvider } from '@mantine/core';
import { Toaster } from 'sonner'

function App() {
  return (
    <BrowserRouter>
      <Toaster richColors position="top-center" />
      <MantineProvider>
        <AppLayout />
      </MantineProvider>
    </BrowserRouter>
  );
}

export default App;
